const esTranslations = {
  home: {
    register: "Registrarse",
    login: "Iniciar sesión",
    tandc: "Términos y condiciones",
  },
  login: {
    title: "Ingrese su número de teléfono:",
    button: "Iniciar",
    modalTittle: "Pedimos disculpas",
    modalText: "No encontramos el número de móvil registrado",
    modalButton: "Cambiar número de móvil",
  },
  codeOtp: {
    enterCode: "Ingresa tu código de verificación",
    codeSent: "Se ha enviado un código de 4 dígitos a tu teléfono",
    yourOtpCodeIs: "Tu código OTP es:",
    verifyAndProceed: "Verificar y Continuar",
    didntReceiveCode: "¿No recibiste el código?",
    sendAgain: "Enviar de nuevo",
    waitFirstPart: "Espera durante ",
    waitSecondPart: " segundos",
    apologize: "Lo sentimos",
    wrongCode: "El código que ingresaste es incorrecto.",
    tryAgain: "Intentar de nuevo",
  },
  restaurants: {
    chooseRestaurant: "Elige tu restaurante",
    addRestaurant: "¡Contáctanos para agregar restaurantes!",
  },
  suppliers: {
    title: "Proveedores",
    addSuppliers: "¡Contáctanos para agregar proveedores!",
  },
  menuPrimary: {
    settings: "Ajustes",
    order: "Ordenar",
    record: "Historial",
    chat: "Chat",
  },
  products: {
    title: "Realiza tu pedido",
  },
  productSearcher: {
    placeholder: "Buscar productos",
    findFirstPart: "Usted tiene ",
    findSecondPart: " productos encontrados:",
  },
  favorites: {
    title: "Favoritos",
    findFirstPart: "Usted tiene ",
    findSecondPart: " favoritos encontrados:",
  },
  categoriesMenu: {
    goBack: "Volver",
    favorites: "Favoritos",
    continue: "Continuar",
  },
  orderDetails: {
    orderDetail: "Detalles del pedido",
    paymentDetails: "Detalles del pago",
    net: "Neto",
    tax: "Impuestos",
    currentTotal: "Total actual",
    continue: "Continuar",
  },
  deliveryDetail: {
    orderDetail: "Detalle del pedido",
    address: "Dirección",
    deliver: "Entrega",
    selectDate: "Seleccione una fecha",
    specialRequirements: "¿Requisitos especiales?",
    continue: "Continuar",
  },
  orderSuccessful: {
    title: "¡Éxito!",
    message: "Tu pedido se ha realizado con éxito",
    ordersButton: "Tus pedidos",
    pdfButton: "Descargar PDF",
  },
  record: {
    title: "Tus pedidos",
    searchPlaceholder: "Buscar tus pedidos",
    pastOrders: "Pedidos anteriores",
    currentOrders: "Pedidos actuales",
    orderNumber: "#Pedido:",
    date: "Fecha",
    amount: "Monto",
    viewDetails: "Ver detalles",
    noOrders: "Aún no hay pedidos",
    bttnNoOrders: "Ordena ya",
    noOrdersDate: "No tienes órdenes en esta fecha",
    openDispute: "Disputa abierta",
  },
  pendingRecord: {
    title: "Detalles del pedido",
    tabProducts: "Productos",
    tabReception: "Recepción",
    check: "Verifica tus productos",
    openDispute: "Disputa",
    confirmOrder: "Confirmar pedido",
    supplierDetail: "Detalles del proveedor",
    detailsItem: "Detalles de los productos",
    paymentDetails: "Detalles de pago",
    currentValue: "Total",
    tax: "Impuestos",
    modalTittle: "Gracias por usar",
    modalText:
      "Para ver tus pedidos confirmados, ve a 'Pedidos anteriores' en 'Historial'.",
    modalButton: "Continuar",
    modalEvidenceText:
      "La disputa será revisada por uno de nuestros agentes y te responderemos lo antes posible.",
    warningTitle: "Tienes una disputa abierta",
    warningFirstPart: "Confirmando la orden",
    warningSecondPart: "se cerrará la disputa",
    warningThirdPart: "y confirmas que todo se resolvió.",
    warningConfirm: "Confirmar orden",
    warningCancel: "Cancelar",
    modalMailText: "El correo con las disputas fue enviado al proveedor.",
  },
  reception: {
    title: "¿Qué salió mal?",
    wrong: "Cantidad incorrecta",
    defective: "Defectuoso",
    enterQuantity: "Ingresa la cantidad entregada:",
    addComent: "Agregar un comentario:",
    sendNextOrder: "Enviar con el próximo pedido",
    crediteNote: "Nota de crédito",
    send: "Enviar",
    defectiveQuantity: "Ingresa la cantidad defectuosa:",
    attachPhoto: "Adjuntar foto",
    customUpload: "Agregar evidencia",
    modalTittle: "Disputa creada exitosamente",
    modalText: "Nuestro equipo verificará la información",
    modalButton: "Continuar",
    submitEvidence: "Enviar evidencia",
    sendEmail: 'Enviar correo con disputas',
  },
  settings: {
    title: "Configuración",
    hi: "Hola",
    helpText: "¿Cómo podemos ayudarte?",
    restaurants: "Restaurantes",
    editRestaurants: "Editar tus restaurantes",
    suppliers: "Proveedores",
    editSuppliers: "Editar tus proveedores",
    languages: "Idiomas",
    english: "Inglés",
    spanish: "Español",
    portuguese: "Portugués",
    faq: "Preguntas frecuentes",
    termsAndConditions: "Términos y condiciones",
    logOut: "Cerrar sesión",
  },
  settingsFaq: {
    tittle1: "¿Cómo ",
    tittle2: "podemos ayudarte?",
    question1: "¿Cómo puedo agregar otro restaurante?",
    question2: "¿Cómo puedo agregar un proveedor?",
    question3: "¿Qué es Grownet?",
    question4: "¿Dónde puedo ver mis pedidos actuales?",
    question5: "¿Qué debo hacer si mi pedido llega incompleto?",
    answer1:
      "Contáctate a través de nuestro chat y proporciona el nombre del restaurante que deseas agregar.",
    answer2:
      "Comunícate a través de nuestro chat para agregar al proveedor que necesites. Contamos con los mejores proveedores de vegetales, frutas, bebidas, carnes y mucho más.",
    answer3:
      "Grownet es tu solución para el abastecimiento de tu restaurante. Facilitamos la obtención de suministros, conectándote con los principales proveedores del mercado de Londres para obtener productos frescos y de alta calidad a excelentes precios. Gestiona múltiples locales de restaurante, optimiza tus compras y enfócate en lo que más importa: brindar una excelente experiencia gastronómica. Únete a la revolución en la gestión de suministros con Grownet ¡y haz crecer tu negocio restaurantero hoy!",
    answer4:
      "Dirígete al menú y accede a la sección de 'Historial'. Allí encontrarás tus pedidos actuales y anteriores. Puedes filtrar por fecha y acceder a más detalles sobre todos tus productos.",
    answer5:
      "Ve al menú y accede a la sección de 'Historial'. Busca el pedido en la categoría de 'Pedidos Actuales' donde puedes filtrar por fecha o realizar una búsqueda manual. Selecciona 'Ver detalles' y abre una disputa para el producto que te llegó incompleto.",
  },
};

export default esTranslations;
