const ptTranslations = {
  home: {
    register: "Registrar-se",
    login: "Entrar",
    tandc: "Termos e condições",
  },
  login: {
    title: "Digite seu número de celular:",
    button: "Começar",
    modalTittle: "Pedimos desculpas",
    modalText: "Não encontramos o número de celular registrado",
    modalButton: "Alterar número de celular",
  },
  codeOtp: {
    enterCode: "Insira seu código de verificação",
    codeSent: "Um código de 4 dígitos foi enviado para o seu telefone",
    yourOtpCodeIs: "Seu código OTP é:",
    verifyAndProceed: "Verificar e Continuar",
    didntReceiveCode: "Você não recebeu o código?",
    sendAgain: "Enviar novamente",
    waitFirstPart: "Aguarde ",
    waitSecondPart: " segundos",
    apologize: "Pedimos desculpas",
    wrongCode: "O código que você digitou está incorreto.",
    tryAgain: "Tente novamente",
  },
  restaurants: {
    chooseRestaurant: "Escolha seu restaurante",
    addRestaurant: "Entre em contato para adicionar restaurante!",
  },
  suppliers: {
    title: "Fornecedores",
    addSuppliers: "Entre em contato para adicionar fornecedores!",
  },
  menuPrimary: {
    settings: "Configurações",
    order: "Ordem",
    record: "Registro",
    chat: "Chat",
  },
  products: {
    title: "Faça seu pedido",
  },
  productSearcher: {
    placeholder: "Buscar produtos",
    findFirstPart: "Você tem ",
    findSecondPart: " produtos encontrados:",
  },
  favorites: {
    title: "Favoritos",
    findFirstPart: "Você tem ",
    findSecondPart: " favoritos encontrados:",
  },
  categoriesMenu: {
    goBack: "Voltar",
    favorites: "Favoritos",
    continue: "Continuar",
  },
  orderDetails: {
    orderDetail: "Detalhes do pedido",
    paymentDetails: "Detalhes do pagamento",
    net: "Líquido",
    tax: "Imposto",
    currentTotal: "Valor atual",
    continue: "Continuar",
  },
  deliveryDetail: {
    orderDetail: "Detalhes do pedido",
    address: "Endereço",
    deliver: "Entregar",
    selectDate: "Selecione uma data",
    specialRequirements: "Requisitos especiais?",
    continue: "Continuar",
  },
  orderSuccessful: {
    title: "Bem-sucedido!",
    message: "Seu pedido foi concluído com sucesso",
    ordersButton: "Seus pedidos",
    pdfButton: "Baixar PDF",
  },
  record: {
    title: "Seus pedidos",
    searchPlaceholder: "Pesquisar seus pedidos",
    pastOrders: "Pedidos antigos",
    currentOrders: "Pedidos atuais",
    orderNumber: "#Pedido:",
    date: "Data",
    amount: "Valor",
    viewDetails: "Ver detalhes",
    noOrders: "Ainda não há pedidos",
    bttnNoOrders: "Faça um pedido agora",
    noOrdersDate: "Sem pedidos nesta data",
    openDispute: "Abrir disputa",
  },
  pendingRecord: {
    title: "Detalhes do pedido",
    tabProducts: "Produtos",
    tabReception: "Receção",
    check: "Verifique seus produtos",
    openDispute: "Disputa",
    confirmOrder: "Confirmar pedido",
    supplierDetail: "Detalhes do fornecedor",
    detailsItem: "Detalhes dos produtos",
    paymentDetails: "Detalhes de pagamento",
    currentValue: "Total",
    tax: "Imposto",
    modalTittle: "Obrigado por usar",
    modalText:
      "Para ver seus pedidos confirmados, acesse 'Pedidos antigos' em 'Registro'.",
    modalButton: "Continuar",
    modalEvidenceText:
      "A disputa será analisada por um de nossos agentes e responderemos o mais breve possível.",
    warningTitle: "Você tem uma disputa aberta",
    warningFirstPart: "Confirmando o pedido ",
    warningSecondPart: "a disputa será encerrada ",
    warningThirdPart: "e confirme se tudo está resolvido.",
    warningConfirm: "Confirmar pedido",
    warningCancel: "Cancelar",
    modalMailText: "O e-mail com as disputas foi enviado ao fornecedor.",
  },
  reception: {
    title: "O que deu errado?",
    wrong: "Quantidade errada",
    defective: "Defeituoso",
    enterQuantity: "Digite a quantidade entregue:",
    addComent: "Adicionar um comentário:",
    sendNextOrder: "Enviar com o próximo pedido",
    crediteNote: "Nota de crédito",
    send: "Enviar",
    defectiveQuantity: "Digite a quantidade defeituosa:",
    attachPhoto: "Anexar foto",
    customUpload: "Adicionar evidências",
    modalTittle: "Disputa criada com sucesso",
    modalText: "Nossa equipe verificará as informações",
    modalButton: "Continuar",
    submitEvidence: "Enviar evidência",
    sendEmail: 'Enviar e-mail com disputas',
  },
  settings: {
    title: "Configurações",
    hi: "Olá",
    helpText: "Como podemos te ajudar?",
    restaurants: "Restaurantes",
    editRestaurants: "Editar seus restaurantes",
    suppliers: "Fornecedores",
    editSuppliers: "Editar seus fornecedores",
    languages: "Idiomas",
    english: "Inglês",
    spanish: "Espanhol",
    portuguese: "Português",
    faq: "Perguntas frequentes",
    termsAndConditions: "Termos e Condições",
    logOut: "Sair",
  },
  settingsFaq: {
    question1: "Como eu adiciono outro restaurante?",
    question2: "Como eu adiciono um fornecedor?",
    question3: "O que é o Grownet?",
    question4: "Onde vejo meus pedidos atuais?",
    question5: "Um pedido recebido está incompleto. O que devo fazer?",
    answer1:
      "Entre em contato conosco através do nosso chat e informe o nome do restaurante que deseja adicionar.",
    answer2:
      "Entre em contato através do nosso chat para adicionar o fornecedor que você precisa. Lembre-se de que temos os melhores fornecedores de legumes, frutas, bebidas, carnes e muito mais.",
    answer3:
      "Descubra o Grownet: a solução de suprimentos para o seu restaurante. Facilitamos a obtenção de suprimentos, conectando-o aos principais fornecedores do mercado de Londres para obter produtos frescos e de alta qualidade a excelentes preços. Gerencie vários locais de restaurante, otimize suas compras e concentre-se no que mais importa: proporcionar uma excelente experiência gastronômica. Junte-se à revolução na gestão de suprimentos com o Grownet e eleve o seu negócio restaurante hoje!",
    answer4:
      "Acesse o menu e vá para a seção 'Registro'. Lá, você encontrará seus pedidos atuais e anteriores. Você pode filtrar por data e acessar mais detalhes sobre todos os seus produtos.",
    answer5:
      "Vá para o menu e acesse a seção 'Registro'. Procure o pedido na categoria de 'Pedidos Atuais', onde você pode filtrar por data ou fazer uma busca manual. Selecione 'Ver detalhes' e abra uma disputa para o produto que chegou incompleto.",
  },
};

export default ptTranslations;
