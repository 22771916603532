const enTranslations = {
  home: {
    register: "Register",
    login: "Login",
    tandc: "Terms & conditions",
  },
  login: {
    title: "Enter your mobile number:",
    button: "Let’s Begin",
    modalTittle: "We apologize",
    modalText: "We didn’t find the mobile number registered",
    modalButton: "Change mobile number",
  },
  codeOtp: {
    enterCode: "Enter your verification code",
    codeSent: "A 4-digit code has been sent to your phone",
    yourOtpCodeIs: "Your OTP code is:",
    verifyAndProceed: "Verify & Proceed",
    didntReceiveCode: "Didn't you receive the code?",
    sendAgain: "Send again",
    waitFirstPart: "Wait for ",
    waitSecondPart: " seconds",
    apologize: "We apologize",
    wrongCode: "The code you entered is wrong.",
    tryAgain: "Try again",
  },
  restaurants: {
    chooseRestaurant: "Choose your restaurant",
    addRestaurant: "Contact us to add a restaurant!",
  },
  suppliers: {
    title: "Suppliers",
    addSuppliers: "Contact us to add suppliers!",
  },
  menuPrimary: {
    settings: "Settings",
    order: "Order",
    record: "Record",
    chat: "Chat",
  },
  products: {
    title: "Make your order",
  },
  productSearcher: {
    placeholder: "Search for products",
    findFirstPart: "You have ",
    findSecondPart: " found products:",
  },
  favorites: {
    title: "Favorites",
    findFirstPart: "You have ",
    findSecondPart: " favorites products:",
  },
  categoriesMenu: {
    goBack: "Go back",
    favorites: "Favorites",
    continue: "Continue",
  },
  orderDetails: {
    orderDetail: "Order detail",
    paymentDetails: "Payment details",
    net: "Net",
    tax: "Tax",
    currentTotal: "Current value",
    continue: "Continue",
  },
  deliveryDetail: {
    orderDetail: "Order detail",
    address: "Address",
    deliver: "Deliver",
    selectDate: "Select a date",
    specialRequirements: "Any special requirements?",
    continue: "Continue",
  },
  orderSuccessful: {
    title: "Successful!",
    message: "Your order is successful",
    ordersButton: "Your orders",
    pdfButton: "Download PDF",
  },
  record: {
    title: "Your orders",
    searchPlaceholder: "Search your orders",
    pastOrders: "Past orders",
    currentOrders: "Pending orders",
    orderNumber: "#Order:",
    date: "Date",
    amount: "Amount",
    viewDetails: "View details",
    noOrders: "No orders yet",
    bttnNoOrders: "Order now",
    noOrdersDate: "No orders on this date",
    openDispute: "Open dispute",
  },
  pendingRecord: {
    title: "Order details",
    tabProducts: "Products",
    tabReception: "Reception",
    check: "Check your products",
    openDispute: "Dispute",
    confirmOrder: "Confirm order",
    supplierDetail: "Supplier details",
    detailsItem: "Detail items",
    paymentDetails: "Payment details",
    currentValue: "Current value",
    tax: "Tax",
    modalTittle: "Thank you for using",
    modalText:
      "To view your confirmed orders, go to 'Past orders' under 'Record'.",
    modalButton: "Continue",
    modalEvidenceText:
      "The dispute will be reviewed by one of our agents and we will respond to you as soon as possible.",
    warningTitle: "It has an open dispute",
    warningFirstPart: "By confirming the order",
    warningSecondPart: "the dispute will be closed",
    warningThirdPart: "and you confirm that everything was resolved.",
    warningConfirm: "Confirm order",
    warningCancel: "Cancel",
    modalMailText: "The email with the disputes was sent to the supplier.",
  },
  reception: {
    title: "What went wrong?",
    wrong: "Wrong quantity",
    defective: "Defective",
    enterQuantity: "Enter quantity delivered:",
    addComent: "Add a comment:",
    sendNextOrder: "Send with next order",
    crediteNote: "Credit note",
    send: "Send",
    defectiveQuantity: "Enter defective quantity:",
    attachPhoto: "Attach photo",
    customUpload: "Add evidence",
    modalTittle: "Successfully created dispute",
    modalText: "Our team will verify the information",
    modalButton: "Continue",
    submitEvidence: "Submit evidence",
    sendEmail: 'Send email with disputes',
  },
  settings: {
    title: "Settings",
    hi: "Hi",
    helpText: "How can we help you?",
    restaurants: "Restaurants",
    editRestaurants: "Edit your restaurants",
    suppliers: "Suppliers",
    editSuppliers: "Edit your suppliers",
    languages: "Languages",
    english: "English",
    spanish: "Spanish",
    portuguese: "Portuguese",
    faq: "FAQ",
    termsAndConditions: "Terms & conditions",
    logOut: "Log out",
  },
  settingsFaq: {
    tittle1: "How can ",
    tittle2: "we help you?",
    question1: "How do I add another restaurant?",
    question2: "How do I add a supplier?",
    question3: "What is Grownet?",
    question4: "Where do I view my current orders?",
    question5: "A received order is incomplete. What should I do?",
    answer1:
      "Contact us through our chat and let us know the name of the restaurant you want to add.",
    answer2:
      "Get in touch via our chat to add the supplier you need. Remember, we have the best suppliers for vegetables, fruits, beverages, meats, and much more.",
    answer3:
      "Discover Grownet: Your restaurant's supply solution. We make sourcing supplies a breeze, connecting you with top London market providers for fresh, high-quality products at great prices. Manage multiple restaurant locations, streamline your purchasing, and focus on what matters most – delivering a great dining experience. Join the supply management revolution with Grownet and elevate your restaurant business today!",
    answer4:
      "Go to the menu and access the 'Records' section. There, you'll find your current and previous orders. You can filter by date and view more details about all your products.",
    answer5:
      "Go to the menu and navigate to the 'Records' section. Search for the order under 'Current Orders'. You can filter by date or manually search for the order. Choose 'View details' and open a dispute for the incomplete product.",
  },
};

export default enTranslations;
